@font-face {
  font-family: murase_anjal;
  src: url("../src/assets/fonts/murase_anjal.ttf");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.login-btn:hover {
  color: #fff !important;
}

.index-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vw;
}

.logo-size-img {
  width: 50px;
  height: 50px;
}

.feedback-font-content .ant-radio-group {
  width: 22rem;
}

.pp,
.tc {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ant-tabs-nav {
  width: 100% !important;
}

.textSelect {
  user-select: text !important;
}

.tamil_words_list {
  cursor: pointer !important;
  background-color: rgb(15, 166, 201) !important;
  color: white !important;
  padding: 5px !important;
  margin: 5px !important;
  border-radius: 5px !important;
  border: 1p solid #fff !important;
}

#key-VirtualKeyID .shift {
  width: 140px !important;
}

#key-VirtualKeyID .enter {
  width: 140px !important;
}

#key-VirtualKeyID .tab {
  width: 115px !important;
}

#key-VirtualKeyID .clear {
  width: 115px !important;
}

.test-container {
  text-align: center;
  padding: 0.5rem;
  background-color: #0fa6c9;
  color: #f0f0f0;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  /* width: 100%; */
}

.test-container h2 {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 2px;
}

.otp-input {
  border: 1px solid skyblue;
  background: #f2f3f7e7;
  border-radius: 0.4rem;
}

.otp-input-root {
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.side-menu-icon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  right: 2rem;
}

.app-name {
  font-size: 18px;
  font-weight: 900;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px solid #f0f0f0 !important;
}

.ant-menu-horizontal {
  border-bottom: 0px solid #f0f0f0 !important;
}

.ant-menu {
  background-color: transparent !important;
}

#root {
  display: block !important;
}

/* .ant-layout-header {
	height: 64px !important;
} */

@media (max-width: 780px) {
  .feedback-font-content .ant-radio-group {
    width: 100%;
  }
}

.keyboardCloseIcon label {
  cursor: pointer;
}