/* @import '~antd/dist/antd.css'; */
.overFlow::-webkit-scrollbar {
  display: none !important;
}
.overflow_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconsize {
  font-size: 3em;
}

.antbtn .ant-hover {
  color: white;
}
.signup-btn > span {
  color: white !important;
}
.saved-card {
  box-shadow: -1px 4px 25px -11px rgba(8, 6, 8, 0.31);
  padding: 1rem;
}
.login-card {
  width: 22rem;
}

/* -----------------------------SignIn- Start---------------------------------- */
@media (max-width: 320px) {
  .login-card {
    width: 100%; /* Adjust the width as needed */
    max-width: 18rem; /* Set the maximum width */
  }
  #newaccont-link {
    font-size: 10px;
  }
}

/* -----------------------------SignIn- End---------------------------------- */

.err-msg {
  color: #ff4d4f;
}
.word-card {
  width: 18rem;
}
.Paginationposition {
  margin-top: 2rem;
}

.ant-dropdown-menu-title-content {
  margin-right: -20px;
}
.cursor-pointer.mr-1 {
  margin-left: -6px;
}
.section {
  margin-bottom: 2rem;
  display: flex;
}
#search-input-in-tamil .ant-select-selection-search .ant-input-affix-wrapper {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.Paginationposition {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  background-color: transparent;
  margin-top: 13px;
  padding: 10px 0;
}
/* .CP_ERR,
#CP_ERR {
  position: relative;
  left: 12%;
} */
.anticon-close {
  margin-top: 5px;
}
.react-skeleton-load {
  width: 230px !important ;
}

#new_password_help.ant-form-item-explain {
  margin-left: 5.5rem;
}
#old_password_help.ant-form-item-explain {
  margin-left: 4rem;
}
#confirm_new_password_help.ant-form-item-explain {
  margin-left: 1.6rem;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  min-width: 20rem;
}

#first_name-1 {
  width: 30rem;
  border-radius: 1.5rem;
  margin-left: 1rem;
}
#last_name-1 {
  width: 30rem;
  border-radius: 1.5rem;
}

#first_name-2,
#last_name-2 {
  width: 30%;
  border-radius: 1.5rem;
}

#email-1 {
  border-radius: 25px;
  width: 30rem;
  margin-left: 1.5em;
}

#email-2 {
  border-radius: 25px;
  width: 30%;
  margin-left: 2rem;
}

/*--------------------------------------- MyAccount-ChangePassword Section START---------------------------------------------- */

.CurrentPassword_MA,
.NewPassword_MA,
.ConfirmNewPassword_MA {
  border-radius: 1.25rem;
  width: 30vw;
}

.disBtn-T,
.disBtn,
.saveBtn-T,
.saveBtn {
  border-radius: 1.25rem;
  width: 12vw;
  margin-left: 2em;
}

.custom-lg-margin-O {
  margin-top: 0px;
}
.custom-lg-margin-N {
  margin-left: 5%;
}

.ant-input-password-icon svg {
  margin-bottom: 2px;
}

/*------------------------------------- MyAccount-ChangePassword Section END------------------------------------------- */
.whatsappIcon {
  border-radius: 2rem;
  margin-left: -0.4em;
}
.round-icon {
  width: 3rem;
  height: 3.4rem;
  padding-left: 0.3em;
  padding-right: 0.3rem;
  transform: scale(0.8);
}
.round-icon-w {
  margin-top: 0em;
  width: 3.5rem;
  height: 3.5rem;
  margin-left: -0.3em;
  padding-right: 0rem;
  transform: scale(0.8);
}

.round-icon-r {
  margin-top: -0.6rem;
  width: 3rem;
  height: 3rem;
  margin-left: -0.3em;
  padding-right: 0rem;
  transform: scale(0.8);
}
.round-icon-s {
  margin-top: -0.4em;
  width: 3rem;
  height: 3rem;
  margin-left: -0.3em;
  padding-right: 0rem;
  transform: scale(0.8);
}

.ant-input-suffix {
  margin: 0 !important;
}

/*---------------------------------- MyAccount-ChangePassword Section Responsive screen START------------------------------------- */

@media screen and (max-width: 1240px) {
  .CurrentPassword_MA,
  .NewPassword_MA,
  .ConfirmNewPassword_MA {
    width: 35vw;
  }
}

@media screen and (max-width: 1080px) {
  .CurrentPassword_MA,
  .NewPassword_MA,
  .ConfirmNewPassword_MA {
    width: 45vw;
  }
  .disBtn-T,
  .disBtn,
  .saveBtn-T,
  .saveBtn {
    width: 15vw;
  }
}

@media screen and (max-width: 768px) {
  .CurrentPassword_MA,
  .NewPassword_MA,
  .ConfirmNewPassword_MA {
    width: 55vw;
  }
  .disBtn-T,
  .disBtn,
  .saveBtn-T,
  .saveBtn {
    width: 20vw;
  }
}
@media screen and (max-width: 425px) {
  .CurrentPassword_MA,
  .NewPassword_MA,
  .ConfirmNewPassword_MA {
    width: 75vw;
    font-size: 10px;
  }
  .disBtn-T,
  .disBtn,
  .saveBtn-T,
  .saveBtn {
    width: 30vw;
    font-size: small;
  }
}

@media screen and (max-width: 375px) {
  .disBtn-T,
  .disBtn,
  .saveBtn-T,
  .saveBtn {
    width: 35vw;
    margin-left: 1em;
  }
  .container-dis-save {
    display: flex;
  }
}

@media screen and (max-width: 320px) {
  .CurrentPassword_MA,
  .NewPassword_MA,
  .ConfirmNewPassword_MA {
    width: 70vw;
  }
  .disBtn-T,
  .disBtn,
  .saveBtn-T,
  .saveBtn {
    width: 40vw;
    margin-left: 0.3em;
  }
}

@media only screen and (max-width: 1200px) {
  #first_name-2,
  #last_name-2,
  #email-2 {
    width: 30rem;
    border-radius: 1.5rem;
  }

  #email-2 {
    border-radius: 25px;
    width: 30rem;
    margin-left: 35px;
  }
  .custom-lg-margin-O {
    margin-left: 6%;
  }
  .custom-lg-margin-N {
    margin-left: 8%;
  }
}
@media (max-width: 880px) {
  #first_name-2,
  #last_name-2 {
    width: 25rem;
    border-radius: 1.5rem;
  }

  #email-2 {
    border-radius: 25px;
    width: 25rem;
    margin-left: 2rem;
  }

  .custom-lg-margin-N {
    margin-left: 12%;
  }
  .custom-lg-margin-O {
    margin-left: 8%;
  }
}

@media (max-width: 580px) {
  .tabFont {
    font-size: 15px;
  }

  #first_name-2,
  #last_name-2,
  #email-2 {
    width: 90%;
    height: 20%;
    border-radius: 1.563rem;
    margin-left: 10px;
  }
  .custom-lg-margin-N {
    margin-left: 1%;
  }
  .custom-lg-margin-O {
    margin-left: 1%;
  }
}

/*---------------------------------- MyAccount-ChangePassword Section  Responsive screen END------------------------------------- */

/*-----------------------Footers start-----------------------*/
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(231, 247, 250, 0.3);
}
#underline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#underline hr {
  background-color: black;
  height: 3px;
  min-width: 35vw;
}
#underline a {
  margin-right: 20px;
  font-size: 24px;
  transition: transform 0.4s;
}
#underline a:hover {
  transform: scale(2);
}
.footerContent {
  text-align: center;
}

.youtubeicon {
  &:hover {
    color: #ff0000;
  }
}
.mailicon {
  &:hover {
    color: #c20806;
  }
}
.accountRecovery {
  width: 400px;
}

/* --------------------------------------------AccountRecover Responsive Start-------------------------------------------- */

@media (max-width: 425px) {
  .accountRecovery {
    width: 235px;
    margin-right: -2rem;
  }
}

/* --------------------------------------------AccountRecover Responsive End-------------------------------------------- */

/* --------------------------------------------TermsAndCondition Responsive Start-------------------------------------------- */

.Tc_font {
  font-size: 2.5rem;
  font-weight: 400;
}

/* .PP_Content */

@media (max-width: 425px) {
  .Tc_font {
    font-size: 20px;
    font-weight: 600;
    margin-top: 3rem !important;
  }

  .description-pp {
    font-size: 14px !important;
  }

  .Tc_Heading {
    font-size: 17px !important;
  }

  .PP_Font {
    font-size: 20px !important;
    font-weight: 600;
    margin-top: 3rem !important;
  }
  .PP_Heading {
    font-size: 17px !important;
  }
  .PP_Content {
    font-size: 14px !important;
  }
}

/* --------------------------------------------TermsAndCondition Responsive End-------------------------------------------- */

/* --------------------------------------------PrivacyPolicy Responsive Start-------------------------------------------- */
.description-pp {
  font-size: 20px;
}

.PP_Font {
  font-size: 2.5rem;
}

.PP_SubContent {
  margin-left: 1.3rem;
}
/* --------------------------------------------PrivacyPolicy Responsive End-------------------------------------------- */

/* TermsAndCondition,privacyPolicy & disclaimer header in login page -public route*/

.initialLoginButton {
  border-radius: 20px;
  width: 145px;
  height: 40px;
  border: 2px solid #5cd0ff;
  cursor: pointer;
}

.initialLoginButton {
  &:hover {
    box-shadow: 1px 1px 15px #5cd0ff;
  }
}

.custom-link {
  text-decoration: underline;
  font-size: 10px;
  display: flex;
  gap: 0.1rem;
}

.custom-link:hover {
  text-decoration: none;
}

/* logo in logincard and....   start */
.login-logo {
  margin-right: 20%;
}

.logo_SignUp {
  margin-right: 45%;
}

.logo_ForgetPassView,
.logo_OtpVerification,
.logo_SetNewPassword,
.logo_SignUpOtp {
  margin-left: 40%;
}

.logoswitch {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}
/* logo in logincard and....  Responsive- start */
@media (max-width: 426px) {
  .logoswitch {
    justify-content: space-between;
  }

  .BySignin {
    font-size: 12px;
  }
}

/* logo in logincard and....  Responsive- End */

/* logo in logincard and....   End */

/*---------------------------------------- Idioms SkeletonLoader START ----------------------------------------*/

.idioms-skeleton-card {
  padding: 1rem;
  border: 1px solid #d1d5db;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  transition: filter 1s;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 150px; /* For card height */
}
.idioms-skeleton-card div {
  background-color: #ccc; /* Placeholder color for skeleton loading effect */
  height: 1rem; /* Adjust height for each skeleton element */
  margin-bottom: 0.5rem;
  border-radius: 4px;
  animation: pulse 1.5s infinite; /* Smooth loading animation */
}

.idioms-skeleton-title {
  width: 60%;
}

.idioms-skeleton-text {
  width: 80%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/*------------------------------ Idioms SkeletonLoader END ----------------------------------------*/
