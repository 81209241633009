@font-face {
  font-family: murase_anjal;
  src: url("../assets/fonts/murase_anjal.ttf");
}

.ant-checkbox-group-item {
  /* padding: none !important; */
  /* display: inline-block !important; */
  margin-right: 8px !important;
  margin-bottom: 10% !important;
  font-size: larger !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #40bfddb0 !important;
}


.forgot-card-container-ac {
  width: 24rem;
}

.recently-list .ant-row {
  flex-wrap: wrap !important;
}

.fixSearchPosition .ant-input-group-addon {
  background-color: #0fa6c9 !important;
}

#empty_img {
  width: 10rem;
  height: 5rem;
}

.search-icon .anticon-search {
  margin-top: 0px !important;
  color: #fff;
  font-weight: 900;
}

.search-container {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
}

.search-content {
  width: 40rem;
}

.search-content .ant-input-group-addon {
  background-color: #0fa6c9 !important;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.fixSearchPosition .ant-input-group-addon>button {
  background-color: #0fa6c9 !important;
}

.fixSearchPosition .ant-btn-icon {
  color: #fff;
}

#intro-content {
  height: 250px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 999999;
  /* border: solid red; */
  bottom: 20px;
}

#intro-content::before {
  /* background-color: #0fa7c9b0; */
  opacity: 0.1;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;

}

.pagination {
  z-index: 999999;
}

#key-VirtualKeyID {
  /* top: 15vh; */
  right: 0%;
}

.link-menu-item-icon,
.ant-dropdown-menu-title-content {
  vertical-align: -2px !important;
  padding-right: 10px !important;
}

.link-drawer-menu-item-icon {
  vertical-align: -4px !important;
  padding-right: 10px !important;
}

.link-menu-item-logout-icon {
  margin-right: 1px !important;
}

.vertical-menu-header {
  padding: 21px 0px 10px 0px;
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
}

.vertical-menu-switch {
  margin: 0px 0px -57px 65%;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 14px;
}

.ant-tabs-nav {
  width: 65%;
}

.custom-hover:hover {
  background-color: transparent !important;
  color: rgb(57, 167, 211) !important;
}

img,
video {
  width: 100%;
}

.wordCardImg {
  width: 100%;
  height: 100%;
}

.ant-layout-header {
  padding: 0 16px !important;
  box-shadow: 0 0 0;
}

.round-icon {
  width: 2rem;
  margin-top: 0em;
  height: 2.5rem;
  padding-left: 0.2em;
  padding-right: 0.2rem;
  margin-left: -0.2rem;
  transform: scale(0.8);
}

.twittericon {
  width: 2rem;

  &:hover {
    color: #00acee;
    background-color: rgb(227, 227, 238);
  }
}

.custom-pagination .ant-pagination-item-link {
  border-color: rgb(15, 166, 201);
  /* Change the text color */
}

.custom-pagination .ant-pagination-item-active {
  background-color: rgb(15, 166, 201);
  border-color: transparent;
}

.custom-pagination .ant-pagination-item-active>a {
  color: rgb(255, 255, 255) !important;
  /* Change the text color */
}

/* .ant-layout{
  box-shadow: 0 0 0 !important;
} */
/* .react-multiple-carousel__arrow {
    background: rgba(255, 255, 255, 0.603) !important;
    outline:  white !important;
}
.react-multiple-carousel__arrow:hover {
    outline: 5px auto white !important;
} */
.imgstyle {
  height: 18px;
  width: 18px;
  vertical-align: middle !important;
  justify-content: center;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: 0 0 0;
}

.radiusborder {
  background-color: white;
  box-shadow: gray;
  border-radius: 23px;
  padding: 8px;
  box-shadow: #40bfddb0 !important;
  background-color: blue;
  vertical-align: middle !important;
  /* margin-top: 20px; */
}

.ant-radio-inner {
  border-color: #8a8a8a !important;
}

.graySpeaker {
  margin-top: 1.22rem;
  margin-left: 1.23rem;
  margin-bottom: 1.27rem;
  box-shadow: #8a8a8a;
}

.graySave {
  margin-top: 1.22rem;
  margin-left: 1.23rem;
  margin-bottom: 1.27rem;
}

.grayCopy {
  margin-top: 1.22rem;
  margin-left: 1.23rem;
  margin-bottom: 1.27rem;
}

.grayShare {
  margin-top: 1.22rem;
  margin-left: 1.23rem;
  margin-bottom: 1.27rem;
}

.ant-btn-primary {
  color: #fff !important;
  background: rgb(15, 166, 201) !important;
  border-color: rgb(15, 166, 201) !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  /* box-shadow: 0 2px 0 rgb(0 0 0 / 5%); */
  box-shadow: 0 0 0 !important;
}

.ant-switch-checked {
  background-color: rgb(15, 166, 201) !important;
}

.anticon {
  cursor: pointer;
}

.fixSearchPosition {
  margin-top: -6px;
}

.Subhead1_Privacy p {
  font-size: 18px;
}

.app-logo-name {
  color: #fff !important;
  position: absolute;
  top: 2%;
  left: 8%;
  transform: scale(1.1);
  font-size: 26px;
  font-weight: 600;
}

.search-not-found-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.app-footer {
  background: rgb(231, 231, 231);
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  align-content: center;
  width: 100%;
  gap: 1rem;
  /* position:absolute;
  bottom: 0; */
}

.centered {
  margin-left: 0.5rem;
}

.description-pp {
  font-size: 20px !important;
}

.cp-container {
  margin-bottom: 0.25rem;
}

.app-footer a,
.footer-text-cp {
  font-weight: 500;
  font-size: 16px;
}

.ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
  padding: 17px 30px !important;
}

.ant-tabs-tab-btn {
  font-size: 18px !important;
  font-weight: bold !important;
}

::-webkit-scrollbar {
  width: 0.7em !important;
}

::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 59.2%, 0.65) !important;
  outline: 1px solid hsla(0, 0%, 100%, 0);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #878686;
}

.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

.layout-header-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.Layout-Background {
  width: 100%;
  position: absolute;
  object-fit: cover;
  height: 100vh;
}

.ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.word-card-loader {
  align-items: center !important;
  justify-content: center !important;
}

.ant-message-error>span>svg {
  margin-top: -9px;
}

.ant-message-success>span>svg {
  margin-top: -9px;
}

.w-23 {
  width: 23rem;
}

.header-app-name {
  /* width: 25%; */
  font-size: 22px !important;
}

.header-search {
  width: 25%;
  height: 4rem;
}

.header-menu {
  width: 45.6%;
}

.word-view-main-card {
  width: 100%;
  /* width: 100vw; */
}

.switch-responsive-display-vertical {
  display: none !important;
  width: 100%;
}

.switch-responsive-display {
  display: block !important;
}

.res-tamil-word-meaning-overflow {
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.mic-card-ui {
  width: 18rem;
  margin-left: 1rem;
}

.ant-dropdown-menu-item:hover {
  background-color: #e7e7e7e7 !important;
}

.word-card-icon {
  gap: 4rem;
}

.mic-container-cards {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
}

.wordcardEdit {
  display: flex;
  width: 100%;
  height: 38rem;
}

.relatedword {
  height: 10rem;
}

.word-card-image {
  height: 60%;
}

/* .recently-list :where(.css-dev-only-do-not-override-17sses9).ant-col-6 {
  max-width: 100%;
} */

@media (max-width: 1025px) {
  .header-app-name {
    width: 25%;
  }

  .header-search {
    width: 33.3%;
  }

  .header-menu {
    width: 43.1%;
  }

  .toggle-header-icon {
    width: 60%;
  }

  word-card-icon {
    gap: 2rem;
  }

  .wordcardEdit {
    flex-wrap: wrap;
    width: 75%;
  }

  .word-card-image {
    height: 85%;
  }
}

@media (max-width: 768px) {
  .toggle-header-icon {
    width: 46%;
  }

  .landing-image {
    width: 100% !important;
  }

  .landing-style {
    padding-left: 1rem !important;
  }

  .header-search {
    width: 43.3%;
  }

  .wordcardEdit {
    flex-wrap: wrap;
    width: 100%;
  }

  word-card-icon {
    gap: 2.5rem;
  }
}

@media (max-width: 431px) {
  .header-search {
    width: 63.3%;
  }

  .toggle-header-icon {
    width: 18%;
  }

  .bg-hidden {
    display: none !important;
  }

  .word-view-main-card {
    padding-left: 0rem;
    padding-right: 0rem;
    height: 45%;
    display: table;
  }

  .switch-responsive-display-vertical {
    display: block !important;
  }

  .switch-responsive-display {
    display: none !important;
    margin-top: 9em;
  }

  /* .word-card-res-all-padding {
    padding: 0px 5px !important;
  } */
  word-card-icon {
    gap: 1rem;
  }

  .switch-responsive-word-card {
    display: contents;
  }

  .relatedword {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .related-menu {
    margin-top: 70%;
  }

  /* .word-card-res-all-padding {
    marginleft: "10%";
  } */
  .word-card-image {
    height: 16rem;
  }
}

@media (max-width: 431px) {
  /* .mobile-switch {
    margin-top: 50%;
  } */
}

@media (max-width: 376px) {
  .header-search {
    width: 56.3%;
  }

  .toggle-header-icon {
    width: 23%;
  }

  .word-card-image {
    height: 14rem;
  }
}

@media (max-width: 320px) {
  .header-search {
    width: 72%;
    padding: 0% 2%;
    margin: 0% !important;
  }

  .toggle-header-icon {
    width: 15%;
  }

  .word-card-image {
    height: 12rem;
  }
}

@media (min-width: 768px) {
  .login-card-width {
    min-width: 350px !important;
  }
}

.p-t-1-3 {
  padding-top: 1.3rem;
}

.react-multi-carousel-item {
  width: 320px !important;
}

.header-menu-item-margin {
  padding: 0px !important;
  margin: 0px !important;
  margin-right: 2.5rem !important;
  font-weight: 500;
}

@media (max-width: 1025px) {
  .header-menu-item-margin {
    margin: 0px !important;
    margin-right: 1.5rem !important;
  }
}

.header-menu-item-margin1 {
  padding: 0px !important;
  margin: 0px !important;
  margin-right: 2rem !important;
  margin-left: 1rem !important;
}

.ant-layout-header {
  height: 58px !important;
  box-shadow: 0 0 0;
}

.height-word-img {
  height: 15rem;
}

.textareaClass {
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 2px;
}

textarea:focus-visible {
  outline: 1px solid #c1c1c1;
}

.ant-Input:focus {
  box-shadow: 0px, 0px, 0px !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  -webkit-box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
  box-shadow: 0 0 0 0px rgb(24 144 255 / 20%) !important;
}

/* textarea:focus-visible{
  border:none !important;
} */
.feedbackcard {
  z-index: 2;
}

/* .anticon svg {
  display: inline-block;
  align-items: center;
  align-self: center;
  width: 21px;
  height: 23px
} */

.anticon-search {
  margin-top: 8px;
}

.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-horizontal>.ant-menu-submenu::after {
  left: 0px !important;
  right: 0px !important;
}

.switchBtn {
  padding: 5px;
  border: 1px solid lightgray;
  /* box-shadow: 2px 2px 5px gray; */
  /* border-radius: 5px; */
}

/* .ratingKeyboard{
  margin-top: -1138px;
    margin-left: 409px;
} */

.headerKeyboard-search {
  position: fixed;
  right: 0;
}

.ratingKeyboard {
  z-index: 999999;
}

.ratingKeyboard>div {
  position: absolute;
  right: 0;
  top: 15%;
}

/* .feedback-container{
  position: relative;
} */
.ratingKeyboard .bgColor {
  position: relative;
  left: 0%;
  bottom: 43%;
  z-index: 999999;
}

.ant-tabs-ink-bar {
  background: #009dbe !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #009dbe !important;
}

.ant-tabs-tab:hover {
  color: #009dbe !important;
}

.ant-menu-horizontal>.ant-menu-item-selected a,
.ant-menu-title-content>a:hover {
  color: #00acce !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
  border-bottom: 2px solid #00acce !important;
}

.ant-spin-text {
  color: #00acce !important;
}

.ant-spin-dot-item {
  background-color: #00acce !important;
}

.ant-form-item-label>label {
  font-size: 14px !important;
  padding-left: 9px !important;
  /* text-align: center !important; */
}

.ant-form-item1 {
  margin-bottom: 0px !important;
  /* text-align: center !important; */
}

.shadow-icon {
  border-radius: 50% !important;
  box-shadow: 1px 1px 15px #d3d3d366;
}

.blue-shadow-icon {
  border-radius: 50% !important;
  box-shadow: 1px 1px 15px #00a7d13d;
}

/* ----------------------------WordCard page Start--> Share,bookmark Icon --------------------------------- */
.shadow-icon-share {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #d3d3d366;
  font-size: 25px;
  height: 47px;
  width: 47px;
  align-items: center;
  justify-content: center;
}

.blue-shadow-icon-share {
  border-radius: 50% !important;
  box-shadow: 1px 1px 15px #00a7d13d;
  font-size: 25px;
  height: 47px;
  width: 47px;
  align-items: center;
  justify-content: center;
}

.shadow-icon-bookmark {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #1f1e1e49;
  height: 20px;
  padding: 12px;
  width: 27px;
}

.blue-shadow-icon-bookmark {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #00a7d13d;
  height: 20px;
  padding: 12px;
  width: 27px;
}

.shadow-icon-copy-wordcard {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #d3d3d366;
  padding: 8px;
  height: 30px;
  width: 36px;
}

.blue-shadow-icon-wordcard {
  height: 46px;
  padding-right: 7px;
}

/* ----------------------------WordCard page End--> Share,bookmark Icon--------------------------------- */

/* ----------------------------HorizontalCard page Start--> Share,bookmark,Copy,CopyCircle Icon--------------------------------- */

.shadow-icon-share-Horizontal {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #d3d3d366;
  font-size: 20px;
  height: 41px;
  width: 39px;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-top: 6px;
}

.blue-shadow-icon-share-Horizontal {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #00a7d13d;
  font-size: 20px;
  height: 41px;
  width: 39px;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-top: 6px;
}

.shadow-icon-bookmark-Horizontal {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #d3d3d366;
  height: 19px;
  padding: 12px;
  width: 21px;
  background: #fff;
  margin-right: 34px;
  margin-top: 6px;
}

.blue-shadow-icon-bookmark-Horizontal {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #d3d3d366;
  height: 19px;
  padding: 12px;
  width: 21px;
  background: #fff;
  margin-right: 34px;
  margin-top: 6px;
}

.shadow-icon-copy {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #d3d3d366;
  padding: 8px;
}

.blue-shadow-icon-copycircle {
  border-radius: 50%;
}

/* ----------------------------HorizontalCard page-Start Responsive ---------------------------- */
@media (max-width: 425px) {
  .blue-shadow-icon-bookmark-Horizontal {
    margin-right: 15px;
  }

  .shadow-icon-bookmark-Horizontal {
    margin-right: 15px;
  }
}

/* ----------------------------HorizontalCard page-End Responsive ---------------------------- */

/* ----------------------------HorizontalCard page End--> Share,bookmark,Copy,CopyCircle Icon--------------------------------- */

.offline_header {
  font-weight: bold;
  color: #00acce;
}

.offline {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #00acce !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #00a5c6 !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #00a5c6 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00a5c6 !important;
  border: 0px solid !important;
  outline: 1px solid #00a5c6 !important;
}

input[type="checkbox"] {
  transform: scale(1.5);
  cursor: pointer;
  accent-color: rgba(1, 124, 134, 1) !important;
  margin-top: 1.3% !important;
}

.ant-btn:active {
  color: #00a5c6 !important;
  border-color: #00a5c6 !important;
  background: #fff;
}

/* .ant-input-password-icon.anticon {
    color: #00a5c6 !important;
} */
.ant-input-password-icon,
.anticon-eye {
  color: #00a5c6 !important;
}

#recent-seen-icon {
  color: #333 !important;
}

#recent-seen-icon_active {
  color: #0fa6c9 !important;
}

.fp-email-btn:hover {
  color: #fff !important;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: #00a5c6 !important;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border-color: #00a5c6 !important;
  box-shadow: #00a5c638;
}

.ant-input-search-button:hover,
.ant-input-search-button:active,
.ant-input-search-button:focus {
  color: white !important;
  background-color: #00a5c6 !important;
}

.anticon-close:hover,
.anticon-close:focus,
.anticon-close:active {
  color: #00a5c6 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #00a5c6 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #00a5c6;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #00a5c6;
}

.logo_img path {
  fill: #000;
}

.Autocomplete_box_img {
  align-self: center;
  height: 40px;
  width: 65px;
}

.Autocomplete_box_text {
  text-align: "left";
  font-size: 12px;
}

.Autocomplete_box_text_bold {
  text-align: "left";
  font-size: 12px;
  font-weight: bold;
}

.anticon-translation:hover,
.anticon-translation:focus,
.anticon-translation:active {
  color: #00a5c6 !important;
}

.ant-menu-title-content>a:hover {
  color: #181919 !important;
}

.ant-menu-title-content>a:active .ant-menu-title-content>a:focus {
  color: #00a5c6 !important;
}

.mr-link-1 {
  margin-right: 0.5rem !important;
}

.link-menu-item-logout-icon:hover,
.link-menu-item-logout-icon:active,
.link-menu-item-logout-icon:focus {
  color: #00a5c6 !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #00a5c6 !important;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #00a5c6 !important;
}

.ant-form-item-label>label {
  height: auto !important;
}

.ant-menu-item-selected {
  color: #00a5c6 !important;
}

.login-form>#password {
  background-color: transparent;
}

.setting-items {
  padding: 1rem;
  background: white;
  width: 25rem;
  box-shadow: 4px -2px 25px -15px rgba(0, 0, 0, 0.75);
}

@media (min-width: 780px) {

  .Suggestion,
  .Comments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}

@media (max-width: 568px) {
  .Rating_box {
    left: 15px !important;
  }
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-form-item .ant-form-item-feedback-icon-success {
  color: #00a5c6 !important;
  margin-top: -5px !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-form-item .ant-form-item-feedback-icon-error {
  color: #ff4d4f;
  font-size: 14px;
}

:where(.css-dev-only-do-not-override-17sses9).ant-form-item .ant-form-item-explain-error {
  margin-bottom: 1rem;
}

.Feedback_Header {
  color: #0094b1;
}

/* ----------------------------Rating page--> Feedback section change design Start--------------------------------- */
.Feedback_Upload h1 {
  font-size: 17px;
}

.Feedback_Upload .ant-upload-list-item {
  height: 10vh !important;
}

@media (max-width: 768px) {

  .suggestion .ant-form-item-no-colon,
  .Suggestion label,
  .Feedback_Upload h1,
  .Comments label,
  .Feedback_Header,
  .Upload-btn {
    font-size: 11px !important;
  }
}

/* ----------------------------Rating page--> End------------------------------------------------- */

/* ----------------------------Feedback page--> Feedback section in side menu change design Start--------------------------------- */

.Feedback_Header {
  text-align: center;
  font-weight: bold;
  font-size: x-large;
  padding-top: 3rem;
}

.smiley-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 15px;
}

.smiley {
  width: 55px;
  height: 55px;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
}

.smiley:hover {
  border-radius: 50%;
  background-color: #87d2e4;
}

.smiley.selected {
  background-color: #0fa6c9;
}

.Suggestion,
.Comments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Comments {
  padding-left: 14px;
}

.Feedback_Upload,
.Upload_btn {
  text-align: center;
}

.Upload_btn {
  position: relative;
  width: 100%;
}

.feedback-font-content .ant-radio-input {
  opacity: 1;
}

@media (max-width: 425px) {
  .feedback-font-content {
    width: 100%;
  }
}

/* ----------------------------Feedback page-->End----------------------------------------------------------------------- */

/* ------------------------------------------ Feedback Responsive Start-----------------------------------*/
@media (max-width: 480px) {
  .Upload_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0 !important;
    margin: 0 !important;
  }

  .Feedback_Upload h1 {
    font-size: 18px;
  }

  .recent_view {

    position: relative;
    top: 120px;
  }

  .Suggestion label,
  .Comments label {
    font-size: 9px !important;
  }

  .CommentBox {
    width: 100%;
    margin-left: 5px !important;
  }

  .CommentBox::placeholder {
    font-size: 10px;
  }

  @media (min-width: 321px) and (max-width: 480px) {
    .Upload_btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      padding: 0 !important;
    }
  }

  :where(.css-dev-only-do-not-override-17sses9).ant-btn.ant-btn-lg {
    font-size: 10px;
  }

  /* .Suggestion .ant-form-item-label {
    padding-left: 4rem;
  } */

  .feedback-font-content .ant-radio-wrapper {
    padding-left: 1rem;
  }

  /* conditional class names for mobile screen alignment->>> for commentbox label */
  /*padding for English */
  .comments-padding {
    padding-left: 3rem;
  }

  /* Padding for Tamil */
  .comments-padding-tamil {
    padding-left: 1rem;
  }

  .Feedback_Sidemenu {
    padding-left: 0.5rem;
  }
}

@media (max-width: 1020px) {
  .Feedback_Sidemenu {
    padding-left: 0.5rem;
  }
}

/* -------------------------------------- Feedback Responsive End-----------------------------*/

.fixSearchPosition>div>.ant-select-selector {
  border-width: 0 !important;
  border-style: solid !important;
}

a {
  color: #252525 !important;
}

.menu_header_inactive>a:hover,
.menu_header_inactive>a:focus,
.menu_header_inactive>a:active {
  padding-bottom: 10px;
  color: #00a5c6 !important;
  border-bottom: 3px solid #00a5c6;
}

.menu_header_item>a {
  padding-bottom: 10px;
  color: #00a5c6 !important;
  border-bottom: 3px solid #00a5c6;
}

.ant-layout-footer {
  padding: 10px 50px !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}

/* -------- Footer Responsive Start-------*/
@media (max-width: 320px) {
  .Footer {
    padding-right: 4rem;
  }

  .text-lg-english {
    font-size: 13px;
  }

  .text-lg-tamil {
    font-size: 7px;
  }
}

/*  @media(max-width:425px){ 
  .app-footer{ 
    position: static; 
  } 
} */

/* -------- Footer Responsive End-------*/

.ant-input-clear-icon-has-suffix {
  margin-top: -6px !important;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  padding-bottom: 8px !important;
}

.ant-checkbox-checked:after,
.ant-checkbox-group-item:after {
  border: 0px solid #14335000 !important;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-input:focus {
  border-color: #00a5c6 !important;
  border-style: solid !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #00a5c6 !important;
  border-style: solid !important;
}

.ant-message-warning>.anticon>svg {
  /* display: inline-block !important; */
  margin-top: -7px;
  /* align-items: center !important;
  align-self: center !important; */
}


/* .ant-modal-title {
  color: #00a5c6 !important;
} */

.adjust_button_hover_drawer.ant-btn:hover {
  border-color: white !important;
}

.home-welcome {
  font-weight: bold;
  font-size: 35px;
  margin-top: -2rem;
  padding-bottom: 0.5rem;
}

.landing-style {
  position: relative;
  bottom: 30px;
  /* border: solid red; */
  width: 1000px;
  padding-left: 2rem;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  font-size: 32px;
  /* font-weight: 600; */
}

.landing-page-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
  width: 95%;
  /* border: solid red; */
}

.ant-spin {
  position: fixed !important;
  top: 25% !important;
  z-index: 1000000 !important;
}

.landing-image {
  width: 90%;
  position: relative;
  top: 90px;
  z-index: -1000000;
  left: 50px;
}

.explore-tamil-btn {
  background-color: #0fa6c9;
  color: white;
  font-size: 23px;
  height: 75%;
}

/* .landing-btn{
  font-size: 23px;
  height: 75%;
} */
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.social-icons a {
  font-size: 40px;
  position: relative;
}

.social-lines {
  width: 30vw;
  height: 1px;
  background-color: #000;
}

.centered-content {
  display: flex;
  left: 45%;
  position: relative;
  flex-direction: row;
  align-items: center;
}

.centered-content img {
  max-width: 50px;
}

.centered-content span {
  font-size: 20px;
  font-weight: 600;
}

.verify-btn:hover {
  color: #fff !important;
}

.centered {
  font-size: 17px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  justify-content: center;
  align-content: center;
  margin-left: auto;
}

.centered-breadcrumbs {
  display: flex;
  justify-content: center;
}

.version {
  margin-left: auto;
}

.version span {
  font-size: 12px !important;
}

.global-loader {
  width: 100rem;
  position: fixed !important;
  top: 0 !important;
}

.mic-modal {
  width: 100% !important;
}

.tamil-word-mic {
  font-size: 24px;
  font-weight: 900;
}

.eng-word-mic {
  font-size: 20px;
  color: lightgray;
}

.centered-breadcrumbs .ant-breadcrumb a {
  font-size: 18px;
  font-weight: 600;
}

.header-line-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  width: max-content;
  position: relative;
}

.header-line-container .textSelect {
  padding-left: 13px;
}

.head-line {
  width: 85%;
  height: 5px;
  background: skyblue;
  position: relative;
  margin-left: 2.5rem;
}

/* ----------------------------Home page->WordItemCard page Start--> Image card Hover --------------------------------- */

.card-container:hover .card-img {
  filter: brightness(50%);
  /* Add shading effect on hover */
}

.card-img {
  transition: filter 0.3s;
  /* Add a smooth transition effect */
}

.view-all-card {
  width: 6rem;
  background: transparent;
  border-radius: 5%;
  padding: 1rem;
  border: 2px solid skyblue;
}

.view-all-card img {
  width: 2rem;
}

/* Show icons on hover */
.card-container {
  position: relative;
  width: 14rem;
}

.home-welcome-content {
  font-size: 14px;
  margin-top: 0.5rem;
  font-weight: bold;
}

.search-content .ant-input-affix-wrapper {
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}

.card-img img {
  height: 25vh;
}

.card-container .icons {
  display: none;
  position: absolute;
  top: 0%;
  right: 1%;
  flex-direction: column;
  font-size: 16px;
  margin-right: -33px;
}

/* @media(max-width:1024px){
  .card-container .icons{
    font-size: 16px;
}
} */

.card-container:hover .icons {
  display: flex;
}

.AccountRecoverySignIn {
  color: #35a6c9 !important;
}

/* -------------------------------SignUp Tooltip new Update  start---------------------------------------------------------------- */
.SignUp_Password>.ant-input-wrapper>.ant-input-group-addon {
  border-bottom-right-radius: 25px !important;
  border-top-right-radius: 25px !important;
}

.SignUp_Password>.ant-input-wrapper>.ant-input-affix-wrapper {
  border-bottom-left-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

#password {
  font-size: 12px !important;
}

.Conform_Password .ant-input-suffix {
  font-size: 13px !important;
}

/* -------------------------------SignUp Tooltip new Update  start---------------------------------------------------------------- */
.volumeicon {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(229, 229, 229);
  border-radius: 100%;
  padding: 0.5rem;
  margin: 0.5rem;
  font-size: 15px;
}

.copyicon {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(229, 229, 229);
  border-radius: 100%;
  padding: 0.5rem;
  margin: 0.5rem;
  margin-left: 0px;
}

.card-eye span {
  margin-right: 10px;
}

#newaccont-link {
  color: #35a6c9 !important;
  text-decoration: underline;
}

.li.ant-pagination-item.ant-pagination-item>a {
  color: #fff !important;
}

.feedback-radios {
  width: 40vw;
}

.search-icons-border {
  background: #00bdff5e;
  display: block;
  margin-top: 8px;
  height: 1.5rem;
  width: 2px;
  border-radius: 100%;
  margin-left: 10px;
  margin-right: 5px;
}

.shadow-icon-copy-worditmecard {
  border-radius: 50%;
  box-shadow: 1px 1px 15px #d3d3d366;
  padding: 5px;
  font-size: 10px;
}

#sign-link {
  color: #35a6c9 !important;
}

@media (max-width: 980px) {
  .fixSearchPosition .ant-select-selection-search-input {
    width: 22rem !important;
  }

  .search-container .ant-select-selection-search-input {
    width: 22rem !important;
  }

  .card-img img {
    height: 29vh !important;
  }

  .landing-image {
    display: none;
  }

  .app-logo-name {
    left: 15%;
  }
}

@media (max-width: 768px) {
  .landing-style {
    margin-top: 0;
  }

  .app-logo-name {
    left: 20%;
  }

  .search-container .ant-select-selection-search-input {
    width: 22rem !important;
  }

  .container p {
    font-size: medium;
  }

  .landing-image {
    width: 27rem;
  }
}

@media (max-width: 1024px) {
  .card-img img {
    height: 29vh !important;
  }
}

@media (max-width: 1480px) {
  .card-img img {
    height: 27vh !important;
  }
}

/* -----------------768px screen size-------------- */
@media (max-width: 768px) {
  .card-eye span {
    margin-right: auto;
  }
}

/* -----------------425px screen size-------------- */
@media (max-width: 480px) {
  .volumeicon {
    font-size: 6px;
    padding: 0.3rem;
    margin: 0;
  }

  .forgot-card-container-ac {
    width: auto;
    padding: 0;
    margin: 0;
  }

  .forgot-card-container-ac .ant-card-body {
    padding: 0.5rem !important;
    font-size: 12px !important;
  }

  #otp-number-head {
    font-size: 12px !important;
  }

  .app-footer {
    flex-direction: column-reverse !important;
    padding-bottom: 4rem;
  }

  .search-container .ant-select-selection-search-input {
    width: 18rem !important;
  }

  .cp-container {
    width: 20rem;
  }

  .centered {
    flex-direction: column;
    margin-left: 0.5rem;
  }

  .landing-image {
    display: none;
  }

  .card-container .icons {
    display: none !important;
  }

  .search-content {
    width: 100%;
  }

  .copyicon {
    font-size: 7px;
    padding: 0.3rem;
    margin: 0;
  }
}

@media (max-width: 1020px) {
  .app-footer {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

/* ----------------------------Home page->WordItemCard page End--> Image card Hover --------------------------------- */

/* ----------------------------Home page Start--> Image card--------------------------------- */

/* -----------------768px screen size-------------- */

/* -----------------425px screen size-------------- */
@media (max-width: 480px) {
  .app-logo-name {
    left: 25%;
    font-size: 18px;
    margin-left: 0.5rem;
  }

  .card-img img {
    height: 20vh !important;
  }

  .landing-style {
    padding-left: 2rem;
    width: 100%;
    justify-content: center;
    margin-top: 2rem !important;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    height: 100%;
    justify-content: space-between;
    font-size: 32px;
    /* font-weight: 600; */
  }

  .landing-style>h1 {
    font-size: 20px !important;
  }

  .landing-style>p {
    font-size: 15px !important;
  }

  .home-welcome {
    font-size: 19px;
  }

  .container p {
    font-size: small;
  }

  .feedback-radios {
    width: 100%;
  }

  .explore-tamil-btn {
    font-size: 10px;
    bottom: 13px;
    right: 5px;
  }

  .header-line-container .textSelect {
    font-size: medium;
    margin-left: 7px;
    line-height: 3rem;
  }

  .fixSearchPosition .ant-select-selection-search-input {
    width: 12rem !important;
  }

  .auth-container {
    /* padding: 1rem !important; */
    width: 90% !important;
  }

  .login-auth-container {
    width: 100% !important;
    justify-content: center !important;
  }

  .fixSearchPosition .ant-select-auto-complete {
    width: 70%;
  }

  .verification-card {
    margin: 0 !important;
    padding: 0 !important;
  }

  .forgot-card-container {
    justify-content: center !important;
  }
}

/* ----------------------------Home page End--> Image card --------------------------------- */

/* ----------------------------Header Start--> AvatarFullName SideMenu Responsive --------------------------------- */

@media (max-width: 425px) {
  :where(.css-dev-only-do-not-override-17sses9).ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
    margin-top: 0.7rem;
  }
}

@media (max-width: 1020px) {
  :where(.css-dev-only-do-not-override-17sses9).ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
    margin-top: 0.7rem;
  }
}

/* ----------------------------Header End--> AvatarFullName SideMenu Responsive --------------------------------- */

/* --------------------------------------------Account Deletion Start --------------------------------------------- */

.accountDelete {
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.deleteButton {
  /* background-color: #ff000017; */
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  margin: 1rem;
  /* border-color: red; */
}

.AccDelete {
  display: flex;
  font-size: large;
  font-weight: bold;
  margin-left: 1rem;
}

.AccDelete1 {
  display: flex;
  font-size: 15px;
  margin-left: 1rem;
  margin-top: 2px;
}

.deleteForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-title {
  margin-bottom: 5px;
  font-weight: bold;
}

.ant-popconfirm-description {
  font-size: 14px;
  max-width: 20rem;
  margin-bottom: 5px;
}

.ant-popconfirm-buttons span {
  font-weight: bold;
}

@media (min-width: 767px) {
  .deleteForm {
    width: 20rem;
    margin-left: 10rem;
  }
}

@media (min-width: 1200px) {
  .deleteForm {
    width: 22rem;
    margin-left: 0;
  }
}

.collapsesetting {
  background-color: #0fa6c947;
  width: 40%;
}

@media (max-width: 431px) {
  .collapsesetting {
    background-color: rgb(15, 166, 201);
    width: 100%;
  }
}

/* --------------------------------------------Account Deletion End --------------------------------------------- */

/* --------------------------------------------SharthisApp Start --------------------------------------------- */

.wholecontainer_STA {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.container_STA {
  text-align: center;
  padding: 20px;
  width: 680px;
}

.header_STA {
  font-size: 24px;
  margin-bottom: 16px;
  font-weight: bold;
}

.minagaramlogoContainer_STA {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
}

.minagaramlogo_STA {
  width: 100px;
  height: 100px;
}

.text_STA_1 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
}

.text_STA_1_T {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 14px;
}

.text_STA {
  font-size: 18px;
  margin-bottom: 14px;
}

.text_STA_T {
  font-size: 16px;
  margin-bottom: 14px;
}

.text_STA a {
  color: #0094b1 !important;
  font-weight: bold;
}

.shareIcon_STA {
  background-image: linear-gradient(to right,
      #00a1c5 0%,
      #f5f5f5 70%,
      #2bc0e4 100%);
  transition: 0.5s;
  background-size: 200% auto;
  color: rgb(19, 18, 18);
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0 0 2px #2bc0e4;
  margin-bottom: 20px;
}

.shareIcon_STA:hover {
  background-position: right center;
}



.textBold_STA {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 22px;
}

.textBold_STA_T {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 22px;
}

.shareButton_STA {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.mobileScreen_STA {
  margin-top: 35px;
  margin-left: 70px;
  width: 38%;
  height: 38%;
}


/* -----------------Responsive start ------------------------*/
/* laptop view */
@media (max-width: 1440px) {
  .container_STA {
    max-width: 900px;
  }
}

/* laptop view */
@media (max-width: 1024px) {
  .container_STA {
    max-width: 600px;
  }

  .mobileScreen_STA {
    width: 40%;
    height: 40%;
  }
}

/* Tab view */
@media (max-width: 768px) {
  .shareButton_STA {
    margin-bottom: 20%;
  }

  .mobileScreen_STA {
    display: none;
  }

  .wholecontainer_STA {
    display: flex;
    justify-content: center;
    /* margin-top: -20px; */
  }

  .shareButton_STA {
    display: flex;
    width: 60%;
  }

  .shareButtonContainer_STA {
    display: flex;
    justify-content: center;
  }

  .container_STA {
    margin-top: 3rem;
  }
}

/* mobile view */
@media (max-width: 425px) {
  .shareButton_STA {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .shareButtonContainer_STA {
    display: flex;
    justify-content: center;
  }

  .container_STA {
    margin-top: 3rem;
    scrollbar-width: none;
  }

  .wholecontainer_STA {
    margin-top: -0.5rem;
  }
}

/* ---------------------------------------------------Idioms, Metaphors, and Phrasemes: Common menu named "Figurative Language" --START---------------------------------------------------- */
.ant-collapse-content {
  z-index: 9999999;
  background: white !important;
  position: fixed;
  margin-top: -5px;
  width: 30%;
}

.ant-collapse-header {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 7px !important;
}

.ant-drawer-body {
  padding: 2px 24px 16px 24px !important;
}

.ant-collapse {
  background: transparent !important;
}

.ant-collapse-content-box {
  padding-bottom: 25px !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
}

.ant-dropdown-menu-submenu-arrow {
  margin-top: 5px;
}

@media(max-width:768px) {
  .ant-collapse-item .ant-collapse-expand-icon {
    margin-top: 6px;
  }
}

@media(max-width:468px) {
  .ant-collapse-content {
    margin-top: -5px;
    width: 60%;
  }
}

/* ---------------------------------------------------Idioms, Metaphors, and Phrasemes: Common menu named "Figurative Language" --END---------------------------------------------------- */

/* ----------------------------- wordCard --->hyperlink   START-------------------------------- */

.hyperLinkWord span {
  cursor: pointer;
}

/* ----------------------------- wordCard ---->hyperlink END-------------------------------- */

/* ----------------------------- word-container --->START  -------------------------------- */

.word-container {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
}

@media (max-width: 768px) {

  #word-container {
    margin-left: 0 !important;
  }

  .word-container {
    display: grid;
    grid-template-columns: auto auto;
  }

}

@media(max-width:480px) {
  .word-container {
    gap: 0;
    grid-template-columns: 1fr;
    padding: 1rem !important;
    justify-items: center;
  }

  #word-container {
    margin-top: 0;
  }
}

/* ----------------------------- word-container --->END  -------------------------------- */

/* ----------------------------- Featured,Recent component--->START  -------------------------------- */

.word-container_Recent,
.word-container_Feature {
  display: flex;
  align-items: center;
}

@media(max-width:768px) {

  .word-container_Recent,
  .word-container_Feature {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    justify-items: center;
  }
}

@media(max-width:480px) {

  .word-container_Recent,
  .word-container_Feature {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .landing-style {
    padding-top: 1px;
  }
}

/* ----------------------------- Featured,Recent component--->END  -------------------------------- */

/* ----------------------------- ViewAll --->START-------------------------------- */
.view-btn {
  height: auto;
  padding: 0.5rem;
}

/* ----------------------------- ViewAll ---->END-------------------------------- */

/* ------------------------------------------------------Wordcard Icon,Start------------------------------------------------------------------------------------------------------------ */
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: white;

}

/* .a1111111 {
  border: solid red;
} */

.notification1 {

 position: relative;
 top: 15px;
  width: 35px;
  height: 35px;
  cursor: pointer;

}

/* ------------------------------------------------------Wordcard Icon,End------------------------------------------------------------------------------------------------------------ */
.notication2 {
  /* border: solid red; */
  height: 900px;
  /* Adjust the value as needed */
  overflow-y: auto;
}

.notication3 {
  /* border: solid red; */
  height: 970px;
  background-color: white;
  border-radius: 5px;
}

@media(max-width:1366px) {

  .notication2 {
    /* border: solid red; */
    height: 500px;
    /* Adjust the value as needed */
    overflow-y: auto;
  }

  .notication3 {
    /* border: solid red; */
    height: 500px;
  }

  .notication3 {
    /* border: solid red; */
    height: 570px;
    background-color: white;
  }
}

.notication10 {
  /* font-weight: bold;
  font-size: 15px; */
  display: flex;
  justify-content: space-between;
}

.notication_title {

  /* border: solid red; */
  /* position: relative;
  left: -95px;
  font-size: 15px;
  font-weight: bold; */
  font-size: 15px;
  font-weight: bold;
  width: 350px;
  
}
/* .notication_title_01{
  display: flex;
  justify-content: space-between;

  border: solid red;
} */
.notication11{
  /* border: solid red; */
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background-color:  #ffffff;
  position: relative;
  top: 15px;
  /* border: solid red; */
}
.notication_message{
  /* border: solid red; */
  font-size: 15px;
  padding-left: 45px;
}
.notication_message{
  position: relative;
  bottom: 10px;
}

/* .w1{
  border: solid red;
  position: absolute;
  bottom:5px
} */
 .q1{
  /* border: solid red; */
  margin-right: 10px;
 }
.message_01{
  padding-top: 50px;

}
.message_02{
  /* border: solid red; */
  background-color: rgb(240, 240, 240);
  display: flex;
  padding-left: 20px;
  padding-top:5px ;
  border-radius: 10px;
}
.message_03{
  /* border: solid red; */
  width: 40px;
  height: 40px;
  position: relative;
  top: 15px;
  color: white;

}
.message_05{
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
}
.message_011{
  font-size: 15px;
    font-weight: bold;
}
.message_013{
position: relative;
top: -25px;
}
.message_20{
  padding-top: 10px;
  padding-left: 10px;
}
.message_25{
  padding-left: 25px;
  font-size: 15px;
}